<script>
    import Layout from "../../layouts/main";
    import PageHeader from "@/components/page-header";
    import appConfig from "@/app.config";
    import Teams from '@/services/Teams';
    import Games from '@/services/Games';


    export default {
        components: { Layout, PageHeader },
        page: {
            title: "Man of the match",
            meta: [
                {
                    name: "description",
                    content: appConfig.description,
                },
            ],
        },
        data() {
            return {
                isBusy: false,
                error: null,
                tableData: [],
                title: "Man of the match",
                items: [
                    {
                        text: "Fan Experience",
                    },
                    {
                        text: "Man of the match",
                    },

                ],
                totalRows: 1,
                currentPage: 1,
                perPage: 10,
                pageOptions: [10, 25, 50, 100],
                filter: null,
                filterOn: [],
                sortBy: "age",
                sortDesc: false,
                selectedMatch: '',
                matches: [],
                sortedMatches: [],
                homeTeamLogo: null,
                awayTeamLogo: null,
                homeTeamName: null,
                awayTeamName: null,
                homeTeamScore: null,
                awayTeamScore: null,
                date_formatted: null,
                fields: [
                    {
                        key: "shirt_number",
                        label: "Shirt Number",
                        sortable: true,
                    },
                    {
                        key: "name",
                        label: "Name",
                        sortable: true,
                    },
                    {
                        key: "votes",
                        label: "Votes",
                        sortable: true,
                    },
                ],

            };
        },
        computed: {
            /**
             * Total no. of records
             */
            rows() {
                return this.tableData.length;
            }
        },
        mounted() {
            // Set the initial number of items
            this.totalRows = this.items.length;
        },
        async created() {
            this.matchesData()
        },
        methods: {
            async getMatches(type){
                try {
                    const response = await Teams.getFixtures(type)
                    if(response.data){
                        return response.data;
                    }
                } catch (error) {
                    this.error = error.response.data.error ? error.response.data.error : "";
                }
            },

            async matchesData(){
                var upcomingMatches = await this.getMatches('UPCOMING');
                var pastMatches = await this.getMatches('PAST');
                var pastMatchTemp = [];
                var upcomingMatchTemp = [];

                if(upcomingMatches.fixtures.length > 0){
                    upcomingMatchTemp = upcomingMatches.fixtures;
                }
                if(pastMatches.fixtures.length > 0){
                    pastMatchTemp = pastMatches.fixtures;
                }
                this.matches = upcomingMatchTemp.concat(pastMatchTemp);
                this.sort_matches();
            },

            getMatch(){
                this.toggleBusy();
                Games.getMatchMotm(this.selectedMatch).then(response => {
                    if(response.data.data){
                        this.tableData = response.data.data;
                    }else{
                        this.tableData = [];
                    }

                    if(this.sortedMatches[this.selectedMatch]){
                        this.homeTeamLogo = this.sortedMatches[this.selectedMatch][0].home_team_logo;
                        this.awayTeamLogo = this.sortedMatches[this.selectedMatch][0].away_team_logo;
                        this.homeTeamName = this.sortedMatches[this.selectedMatch][0].home_team_name;
                        this.awayTeamName = this.sortedMatches[this.selectedMatch][0].away_team_name;
                        this.homeTeamScore = this.sortedMatches[this.selectedMatch][0].score.ft_score_home;
                        this.awayTeamScore = this.sortedMatches[this.selectedMatch][0].score.ft_score_away;
                        this.date_formatted = this.sortedMatches[this.selectedMatch][0].date_formatted;
                    }else{
                        this.homeTeamLogo = this.awayTeamLogo =  this.awayTeamLogo = this.homeTeamName = this.awayTeamName = '';
                    }

                    this.toggleBusy();
                }).catch(error => {
                    this.error = error.response.data.error ? error.response.data.error : "";
                    this.toggleBusy();
                })
            },

            sort_matches(){
                this.sortedMatches = this.matches.reduce(function (r, a) {
                    r[a.match_id] = r[a.match_id] || [];
                    r[a.match_id].push(a);
                    return r;
                }, Object.create(null));
            },

            /**
             * Search the table data with search input
             */
            onFiltered(filteredItems) {
                // Trigger pagination to update the number of buttons/pages due to filtering
                this.totalRows = filteredItems.length;
                this.currentPage = 1;
            },

        },
        middleware: "authentication",
    };
</script>

<template>
    <Layout>
        <PageHeader :title="title" :items="items" />
        <div class="row">
            <div class="col-12">
                <div class="card">
                    <div class="card-body">
                        <div class="row mt-4">
                            <div class="col-md-12 d-flex justify-content-center">
                                <div class="col-md-4 mb-3 text-center">
                                    <img v-if="homeTeamLogo != null" :src="homeTeamLogo" width="50px" :alt="homeTeamName">
                                    <p class="font-size-16">{{homeTeamName}}</p>
                                </div>
                                <div class="mb-3 col-md-4">
                                    <div class="form-row form-group mb-3" :class="(matches.length <= 0) ? 'placeholder-glow' :''">
                                        <label class="col-md-12 text-center col-form-label">Select Team</label>
                                        <select class="form-select" :class="(matches.length <= 0) ? 'placeholder' :''" v-model="selectedMatch" @change="getMatch()" id="selectMatch">
                                            <option v-for="match in matches" :key="match.match_id" :value="match.match_id">
                                                {{match.home_team_name}} - {{match.away_team_name}} / <span class="ms-2">{{match.date_time}}</span>
                                            </option>
                                        </select>
                                        <div class="row mt-4 w-100">
                                            <div class="col-md-12 text-center " v-if="homeTeamScore != null">
                                                <span class="me-5 text-strong font-size-18">{{homeTeamScore}}</span> : <span class="ms-5 text-strong font-size-18">{{awayTeamScore}}</span>
                                                <p class="mt-3"><span class="bg-primary text-white p-1">{{date_formatted}}</span></p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-4 mb-3 text-center">
                                    <img v-if="awayTeamLogo != null" :src="awayTeamLogo" width="50px" :alt="awayTeamName">
                                    <p class="font-size-16">{{awayTeamName}}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="card">
                    <div class="card-body">

                        <div class="row mt-4">
                            <div class="col-md-12">
                                <div class="mb-3 text-center">
                                    <h5>Man of the match</h5>
                                </div>
                            </div>
                        </div>

                        <div class="row mt-4 mb-5" v-if="tableData[0]">
                            <div class="col-md-12">
                                <div v-if="tableData[0].player_data[0]" class="mb-3 text-center">
                                    <h5 class="mt-1">{{ tableData[0].player_data[0].first_name + ' ' +  tableData[0].player_data[0].last_name }}</h5>
                                    <h6 class="mt-1">{{ tableData[0].player_data[0].function }}</h6>
                                    <img width='200' :src="tableData[0].player_data[0].img" data-holder-rendered="true" class="img-thumbnail rounded ">
                                    <h6 class="mt-1">Votes: {{ tableData[0].votes }}</h6>
                                </div>
                                <hr>
                            </div>
                        </div>

                        <div class="row mt-4">
                            <div class="col-md-12">
                                <div class="mb-3">
                                    <h5>Voting data</h5>
                                </div>
                            </div>
                        </div>
                        <!-- Table -->
                        <div class="table-responsive mb-0">
                            <b-table
                                    :busy="isBusy"
                                    :items="tableData"
                                    :fields="fields"
                                    responsive="sm"
                                    :per-page="perPage"
                                    :current-page="currentPage"
                                    :sort-by.sync="sortBy"
                                    :sort-desc.sync="sortDesc"
                                    :filter-included-fields="filterOn"
                                    show-empty
                                    empty-text="No Data Found"
                                    @filtered="onFiltered"
                            >

                                <template v-slot:cell(name)="data">
                                    <span v-if="data.item.player_data">
                                        {{ data.item.player_data[0].first_name + ' ' +  data.item.player_data[0].last_name }}
                                    </span>

                                </template>

                                <template v-slot:cell(shirt_number)="data">
                                     <span v-if="data.item.player_data">
                                        {{data.item.player_data[0].shirt_number}}
                                    </span>
                                </template>

                                <template #table-busy>
                                    <div class="text-center text-danger my-2">
                                        <b-spinner class="align-middle"></b-spinner>
                                        <strong>Loading...</strong>
                                    </div>
                                </template>

                            </b-table>
                        </div>
                        <div class="row">
                            <div class="col">
                                <div
                                        class="dataTables_paginate paging_simple_numbers float-end"
                                >
                                    <ul class="pagination pagination-rounded mb-0">
                                        <!-- pagination -->
                                        <b-pagination
                                                v-model="currentPage"
                                                :total-rows="rows"
                                                :per-page="perPage"
                                        ></b-pagination>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </Layout>
</template>
